import { ErrorBoundary, init, reactRouterV6BrowserTracingIntegration, replayIntegration } from "@sentry/react"
import { AnimatePresence } from "framer-motion"
import React, { useEffect } from "react"
import { createRoot } from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"
import { RouterProvider, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import { URLSearchParams } from "url-shim"
import { registerSW } from "virtual:pwa-register"
import { terminal } from "virtual:terminal"

import { ENVIRONMENT_NAME, IS_DEPLOYED } from "~/constants"
import { initializeFirebaseAnalytics } from "~/helpers/analytics"
import { getConsentMode } from "~/helpers/consent-mode"
import { router } from "~/router/router"
import { store } from "~/state/store"
import type { BeforeInstallPromptEvent } from "~/types/browser/globals"

import "~/index.css"

// Ensure the relevant environment variables are set - https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
if (SENTRY_DSN === undefined || SENTRY_DSN === "")
	throw new Error("The environment variable 'VITE_SENTRY_DSN' is empty!")
const BASE_URL = import.meta.env.VITE_BASE_URL
if (BASE_URL === undefined || BASE_URL === "") throw new Error("The environment variable 'VITE_BASE_URL' is empty!")

registerSW({
	// Reload straight away if an update is available - https://vite-pwa-org.netlify.app/guide/auto-update.html#automatic-reload
	immediate: true,

	// Runs when the PWA is ready for full offline use (i.e., all assets cached) - https://vite-pwa-org.netlify.app/guide/auto-update.html#ready-to-work-offline
	onOfflineReady: (): void => {
		console.info("Ready to work offline!")
	}
})

// Store a reference to the PWA install event so it can be called upon later - https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Guides/Making_PWAs_installable#triggering_the_install_prompt
window.addEventListener("beforeinstallprompt", (event): void => {
	globalThis.beforeInstallPromptEvent = event as BeforeInstallPromptEvent | undefined

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if ("global" in window && window.global !== undefined)
		window.global.beforeInstallPromptEvent = event as BeforeInstallPromptEvent | undefined
})

// Store the version & environment for later use with Sentry & Firebase analytics
globalThis.semanticVersion = VITE_BITBUCKET_TAG ?? "0.0.0"
globalThis.environmentName = ENVIRONMENT_NAME
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if ("global" in window && window.global !== undefined) {
	window.global.semanticVersion = globalThis.semanticVersion
	window.global.environmentName = globalThis.environmentName
}

// Only capture 25% of errors when deployed
const errorRate = 1.0 //import.meta.env.PROD && !isStaging ? 0.25 : 1.0

// Initialise Sentry - https://docs.sentry.io/platforms/javascript/guides/react/
init({
	dsn: SENTRY_DSN,

	// Only capture errors when deployed
	enabled: IS_DEPLOYED,

	// Metadata about this build
	release: globalThis.semanticVersion,
	environment: globalThis.environmentName,

	// Compatibility with client-side routing - https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
	integrations: [
		reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
		replayIntegration()
	],

	// Only append Sentry's HTTP headers on requests to the current domain
	tracePropagationTargets: [BASE_URL],

	// Limit the amount of data sent to Sentry - https://docs.sentry.io/platforms/javascript/configuration/sampling/
	sampleRate: errorRate,
	profilesSampleRate: errorRate,
	tracesSampleRate: errorRate,
	replaysOnErrorSampleRate: errorRate,
	replaysSessionSampleRate: errorRate,

	// Track the user's session
	autoSessionTracking: true,

	// Disable client reports, as our Sentry instance doesn't support them :? - https://github.com/getsentry/sentry-javascript/issues/4404
	sendClientReports: false
})

// if (getConsentMode() !== true)
// 	googleTagManager("consent", "default", {
// 		// eslint-disable-next-line @typescript-eslint/naming-convention
// 		ad_storage: "denied",
// 		// eslint-disable-next-line @typescript-eslint/naming-convention
// 		ad_user_data: "denied",
// 		// eslint-disable-next-line @typescript-eslint/naming-convention
// 		ad_personalization: "denied",
// 		// eslint-disable-next-line @typescript-eslint/naming-convention
// 		analytics_storage: "denied"
// 	})

window.addEventListener(
	"load",
	() => {
		// Dump visitor info to dev console for debugging
		if (import.meta.env.DEV) {
			terminal.info(window.navigator.userAgent)
			if ("userAgentData" in navigator) terminal.dir(navigator.userAgentData)
		}

		// Detect how the app was launched - Web, Trusted Web Activity (Android) via Bubblewrap, WKWebKit (iOS) via PWABuilder
		const isAndroidReferrer = document.referrer.includes("android-app://com.myoxygen.handi.taunton") // https://stackoverflow.com/a/54580415
		const isiOSCookie = document.cookie.includes("app-platform=iOS App Store") // https://docs.pwabuilder.com/#/builder/faq?id=how-can-i-tell-if-my-pwa-was-launched-from-the-ios-app
		const queryParameters = new URLSearchParams(window.location.search)
		if (isAndroidReferrer || queryParameters.get("from") === "twa") {
			globalThis.launchedFrom = "twa"
		} else if (isiOSCookie || queryParameters.get("from") === "ios") {
			globalThis.launchedFrom = "ios"
		} else {
			globalThis.launchedFrom = "web"
		}

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if ("global" in window && window.global !== undefined) window.global.launchedFrom = globalThis.launchedFrom

		// On iOS we must wait for App Tracking Transparency authorization before initializing Firebase Analytics
		if (getConsentMode() === true && !isiOSCookie && queryParameters.get("from") !== "ios")
			initializeFirebaseAnalytics()
	},
	{
		once: true
	}
)

// Handles the result of App Tracking Transparency authorization on iOS
window.handleAppTrackingTransparencyAuthorizationStatus = authorizationStatus => {
	console.info(
		`iOS App Tracking Transparency authorization status is '${authorizationStatus}' (${globalThis.appTrackingTransparencyAuthorizationStatus?.toString() ?? "n/a"})!`
	)

	// Store the authorization status for later use, if it wasn't already set by iOS/Swift
	if (globalThis.appTrackingTransparencyAuthorizationStatus === undefined)
		globalThis.appTrackingTransparencyAuthorizationStatus = authorizationStatus

	// Initialize analytics if we've been granted permission to do so
	if (authorizationStatus === "authorized") initializeFirebaseAnalytics()
}

// Get the HTML element to render everything into
const container = document.getElementById("root")
if (container === null) throw new Error("Root HTML container element not found!")

// Register error handlers
const root = createRoot(container, {
	onUncaughtError: (error, info): void => {
		console.error(
			`Uncaught error: '${error?.toString() ?? "Unknown"}' (${info.componentStack?.toString() ?? "No component stack"})`
		)
	},
	onCaughtError: (error, info): void => {
		console.warn(
			`Caught error: '${error?.toString() ?? "Unknown"}' (${info.componentStack?.toString() ?? "No component stack"})`
		)
	},
	onRecoverableError: (error, info): void => {
		console.warn(
			`Recoverable error: '${error?.toString() ?? "Unknown"}' (${info.componentStack?.toString() ?? "No component stack"})`
		)
	}
})

// Render the app
root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<ReduxProvider store={store}>
				<AnimatePresence mode="wait">
					<RouterProvider router={router} />
				</AnimatePresence>
			</ReduxProvider>
		</ErrorBoundary>
	</React.StrictMode>
)
