import { useCallback, useMemo, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { URL } from "url-shim"

import Button from "~/components/standard/button"
import Image from "~/components/standard/image"
import { logFirebaseAnalyticsEvent } from "~/helpers/analytics"
import { chunk, orNull } from "~/helpers/primitives"
import { Routes } from "~/router/routes"
import type { CraftLinksContentBlock } from "~/types/api/craft/fields/content-blocks/links"
import { isCraftAssetLink, isCraftEntryLink, isCraftTelephoneLink, isCraftURLLink } from "~/types/api/craft/link"
import type { Unpack } from "~/types/helpers"
import type { CraftEntryState, PDFViewerState } from "~/types/state"

import ChevronIcon from "~/assets/icons/chevron.svg?react"
import DownloadIcon from "~/assets/icons/download.svg?react"
import PhoneIcon from "~/assets/icons/phone.svg?react"

/* eslint-disable @typescript-eslint/naming-convention */

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const UPLOADS_BASE_URL = orNull(import.meta.env.VITE_UPLOADS_BASE_URL)
if (UPLOADS_BASE_URL === null) throw new Error("The Craft CMS uploads base URL is missing!")

const LinkButton = ({
	block,
	link,

	...props
}: Omit<ComponentPropsWithRef<typeof Button>, "label" | "onClick"> & {
	block: CraftLinksContentBlock
	link: Unpack<CraftLinksContentBlock["links"]>
}): JSX.Element => {
	const navigate = useNavigate()

	const isBroken = useMemo<boolean>(() => {
		if (isCraftTelephoneLink(link.link)) return link.link.number === null
		if (isCraftAssetLink(link.link) || isCraftURLLink(link.link)) return link.link.url === null
		if (isCraftEntryLink(link.link)) return link.link.target === null

		return false // Assume we're fine
	}, [link])

	const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (isBroken) return // Don't do anything if the link is broken

		if (isCraftTelephoneLink(link.link)) {
			if (link.link.number === null) {
				console.warn(`No number for telephone link '${link.link.text?.toString() ?? "N/A"}'?!`)
				return
			}

			logFirebaseAnalyticsEvent("select_content", {
				content_type: "tel",
				content_id: link.link.number
			})

			window.location.href = `tel:${link.link.number}`

			return
		}

		if (isCraftAssetLink(link.link) || isCraftURLLink(link.link)) {
			if (link.link.url === null) {
				console.warn(`No URL for external/asset link '${link.link.text?.toString() ?? "N/A"}'?!`)
				return
			}

			const url = new URL(link.link.url, UPLOADS_BASE_URL)

			logFirebaseAnalyticsEvent("select_content", {
				content_type: "url",
				content_id: url.toString()
			})

			// PDFs are handled differently on iOS due to a bug where the target/download attributes are ignored (i.e., doesn't open in an external window)
			if (url.pathname.toLowerCase().endsWith(".pdf") && globalThis.launchedFrom === "ios") {
				navigate(Routes.PDFViewer, {
					state: {
						url: url.toString()
					} satisfies PDFViewerState as PDFViewerState
				})

				return
			}

			// window.open(url, "_blank", "noopener noreferrer")

			const anchor = document.createElement("a")
			anchor.href = url.toString()
			anchor.target = "_blank"
			anchor.rel = "noopener noreferrer"
			anchor.download = link.link.text?.toString() ?? ""
			anchor.click()

			return
		}

		if (link.link.target === null) {
			console.warn(`No target Craft entry ID for link '${link.link.text?.toString() ?? "N/A"}'?!`)
			return
		}

		logFirebaseAnalyticsEvent("select_content", {
			content_type: "entry",
			content_id: link.link.target.entry.toString()
		})

		navigate(link.link.target.url, {
			state: {
				target: link.link.target
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, isBroken, link])

	const is999 = useMemo<boolean>(() => isCraftTelephoneLink(link.link) && link.link.number === "999", [link])
	const is111 = useMemo<boolean>(() => isCraftTelephoneLink(link.link) && link.link.number === "111", [link])

	let buttonClasses = "text-logo-purple bg-purple-alt/15 hover:bg-purple-alt/25 active:bg-purple-alt/35"
	if (is999) buttonClasses = "text-white bg-algorithm-red hover:bg-algorithm-red/90 active:bg-algorithm-red/80"
	else if (is111)
		buttonClasses = "text-white bg-algorithm-amber hover:bg-algorithm-amber/90 active:bg-algorithm-amber/80"

	let iconClasses = "fill-logo-purple"
	if (is999) iconClasses = "fill-algorithm-red"
	else if (is111) iconClasses = "fill-algorithm-amber"

	return (
		<Button
			{...props}
			label={link.link.text ?? "Missing!"}
			onClick={onClick}
			disabled={isBroken}
			// TODO: Label doesn't vertically align with telephone icon
			className={`gap-y-2 rounded-xl p-3 px-4 text-left ${!isBroken ? buttonClasses : "bg-control-disabled-background"} ${props.className ?? ""}`.trimEnd()}
			icon={
				<div
					className={`aspect-square overflow-hidden ${block.card ? "h-12 min-h-12 w-12 min-w-12" : "h-9 min-h-9 w-9 min-w-9"} ${isCraftTelephoneLink(link.link) ? "rounded-full bg-white p-2" : ""}`.trimEnd()}>
					{/* eslint-disable no-nested-ternary */}
					{isCraftTelephoneLink(link.link) ? (
						<PhoneIcon
							className={`h-full w-full ${!isBroken ? iconClasses : "fill-control-disabled-text"}`.trimEnd()}
						/>
					) : link.icon?.url.endsWith(".svg") === true ? (
						<ReactSVG
							src={`${UPLOADS_BASE_URL}${link.icon.url}`}
							// preserveAspectRatio="none"
							className={`h-full w-full ${!isBroken ? "fill-logo-purple" : "fill-control-disabled-text"}`}
						/>
					) : link.icon !== null ? (
						<Image src={`${UPLOADS_BASE_URL}${link.icon.url}`} alt={link.link.text ?? undefined} />
					) : (
						<div
							className={`h-full w-full rounded-full p-1 ${!isBroken ? "bg-logo-purple" : "bg-control-disabled-text"}`}>
							{isCraftAssetLink(link.link) || isCraftURLLink(link.link) ? (
								<DownloadIcon
									className={`h-full w-full p-1 ${!isBroken ? "fill-white" : "fill-white/75"}`}
								/>
							) : (
								<ChevronIcon
									className={`ms-0.5 h-full w-full rotate-180 p-1 ${!isBroken ? "fill-white" : "fill-white/75"}`}
								/>
							)}
						</div>
					)}
				</div>
			}
		/>
	)
}

const DualColumn = ({
	block,
	...props
}: ComponentPropsWithRef<"div"> & { block: CraftLinksContentBlock }): JSX.Element => {
	const chunks = chunk(block.links, 2) // 2 columns/4 rows

	return (
		<div {...props} className={`flex flex-col flex-wrap gap-y-3 ${props.className ?? ""}`.trimEnd()}>
			{chunks.map((_chunk, num) => (
				<div key={num} className="flex flex-row gap-x-3">
					{_chunk.map((link, index) => (
						<LinkButton
							key={index}
							block={block}
							link={link}
							className="min-h-28 w-1/2 flex-1 flex-col justify-between gap-y-2"
						/>
					))}
				</div>
			))}
		</div>
	)
}

const SingleColumn = ({
	block,
	...props
}: ComponentPropsWithRef<"div"> & { block: CraftLinksContentBlock }): JSX.Element => (
	<div {...props} className={`flex flex-col gap-y-3 ${props.className ?? ""}`.trimEnd()}>
		{block.links.map((link, index) => (
			<LinkButton key={index} block={block} link={link} className="flex-row items-center gap-x-4" />
		))}
	</div>
)

/**
 * A content block that renders links.
 * @returns A React component.
 * @example <LinksContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const LinksContentBlock = ({
	block,
	...props
}: ComponentPropsWithRef<"div"> & { block: CraftLinksContentBlock }): JSX.Element =>
	block.card ? <DualColumn {...props} block={block} /> : <SingleColumn {...props} block={block} />

export default LinksContentBlock
