import type { ComponentPropsWithRef, JSX } from "react"

import Anchor from "~/components/standard/anchor"
import { ENVIRONMENT_NAME, FIREBASE_VERSION, IS_DEPLOYED, REACT_VERSION, SENTRY_VERSION } from "~/constants"
import { useMediaQueries } from "~/hooks/use-media-query"

// The URL to this project's repository
const REPOSITORY_BASE_URL = `https://bitbucket.org/${VITE_BITBUCKET_REPO_FULL_NAME ?? "yellostudio/handi-pwa"}`

/**
 * The footer at the bottom of the settings page during local development & when on the staging environment.
 * @returns The React component.
 * @example <Footer />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const Footer = ({ ...props }: ComponentPropsWithRef<"footer">): JSX.Element => {
	const { isLandscape } = useMediaQueries()

	return (
		<footer
			{...props}
			className={`m-2 flex items-center text-sm text-gray-200 ${isLandscape === true ? "flex-row justify-center gap-x-4" : "flex-col"} ${props.className ?? ""}`.trimEnd()}>
			<p>
				{globalThis.launchedFrom ?? "n/a"} | {globalThis.appTrackingTransparencyAuthorizationStatus ?? "n/a"}
			</p>

			<p>
				{ENVIRONMENT_NAME} ({IS_DEPLOYED ? "deployed" : "local"})
			</p>

			<p>
				<Anchor className="text-inherit" href={`${REPOSITORY_BASE_URL}/src/${VITE_BITBUCKET_TAG ?? ""}`}>
					{VITE_BITBUCKET_TAG ?? "0.0.0"}
				</Anchor>{" "}
				(
				<Anchor className="text-inherit" href={`${REPOSITORY_BASE_URL}/src/${VITE_BITBUCKET_COMMIT ?? ""}`}>
					{VITE_BITBUCKET_COMMIT?.slice(0, 7) ?? "n/a"}
				</Anchor>
				,{" "}
				<Anchor className="text-inherit" href={`${REPOSITORY_BASE_URL}/src/${VITE_BITBUCKET_BRANCH ?? ""}`}>
					{VITE_BITBUCKET_BRANCH ?? "n/a"}
				</Anchor>
				)
			</p>

			<p>
				{REACT_VERSION} / {SENTRY_VERSION} / {FIREBASE_VERSION}
			</p>

			<p>{VITE_BUILT_AT}</p>
		</footer>
	)
}

export default Footer
